import React, { useEffect, useState } from "react";
import { Layout, Button, Table, Modal, Tooltip } from "antd";
import { Sidebar } from "../../../Components/Sidebar/Sidebar";
import { HeaderBar } from "../../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import type { ColumnsType } from "antd/es/table";
// Networking
import api from "../../../Network/interceptor";
import { apiRoutes } from "../../../Network/apiRoutes";

// Components
import PageContentHeader from "../../../Components/CoreComponents/PageContentHeader";
import Loader from "../../../Components/CoreComponents/Loader";

// Images
import images from "../../../Assets/Images";

// Css
import "./BureauList.css";
import withAuth from "../../../Utils/wrapper";

const { Content } = Layout;
const { confirm } = Modal;

function BureauList() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | undefined>(
    undefined
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isStatusChange, setIsStatusChange] = useState(false);

  const PAGE_SIZE = 10;

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let tempSortOrder = "";
      if (sortField && sortOrder) {
        tempSortOrder = `${sortOrder === "ascend" ? "ASC" : "DESC"}`;
      }
      const response = await api.get(
        apiRoutes.bureau +
          `?PageNumber=${
            currentPage - 1
          }&PageSize=${PAGE_SIZE}&SortBy=${sortField}&OrderBy=${tempSortOrder}&Search=${searchQuery}`
      );
      setData(response.data.data);
      setTotalPages(response.data.Pages);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    sortField,
    sortOrder,
    searchQuery,
    isDeleting,
    isStatusChange,
  ]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === "User") {
      setSortField("LegalName");
    } else {
      setSortField(sorter.field || "");
    }
    setSortOrder(sorter.order);
  };
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    handlePageChange(1);
  };
  const handleDelete = async (id: number) => {
    try {
      const response = await api.delete(apiRoutes.bureau + `/${id}`);
      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };
  const handleReInviteBureau = async (id: number) => {
    try {
      const response = await api.post(apiRoutes.bureau + `/${id}/invite`);
      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };
  const handleStatusChange = async (id: number, status: string) => {
    try {
      const data = {
        Status: status,
      };
      const response = await api.post(
        apiRoutes.bureau + `/${id}/status-change`,
        data
      );
      if (response.status === 200) {
        if (isStatusChange === false) {
          setIsStatusChange(true);
        } else {
          setIsStatusChange(false);
        }
        toast.success(response.data.message);
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  const onEditClick = (id: number) => {
    navigate(`/bureau/edit/${id}`);
  };

  const showDeleteConfirm = (id: number) => {
    const modal = confirm({
      title: "Are you sure you want to delete the Bureau?",
      wrapClassName: "delete-modal-wrapper",
      className: "delete-modal",
      okText: "Yes",
      cancelText: "No",
      width: 592,
      centered: true,
      closable: true,
      onOk() {
        if (id !== null) {
          handleDelete(id);
          if (isDeleting === false) {
            setIsDeleting(true);
          } else if (isDeleting === true) {
            setIsDeleting(false);
          }
        }
      },
      onCancel() {
        modal.destroy();
      },
    });
  };

  interface DataType {
    IsActive: boolean;
    ID: number;
    User: { LegalName: string };
    EmployerCount: number;
    Status: string;
    CreatedAt: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "User",
      key: "User",
      render: (item) => item.LegalName,
      sorter: (a, b) => a.User.LegalName.localeCompare(b.User.LegalName),
    },
    {
      title: "Employer Count",
      dataIndex: "EmployerCount",
      key: "EmployerCount",
      sorter: (a, b) => a.EmployerCount - b.EmployerCount,
      render: (EmployerCount) => {
        return (
          <p className="count">{EmployerCount === 0 ? "-" : EmployerCount}</p>
        );
      },
    },
    {
      title: "Status",
      key: "Status",
      dataIndex: "Status",
      sorter: true,
      render: (Status) => {
        let classText = "";
        if (Status === "Pending") {
          classText = "pending";
        } else if (Status === "Active") {
          classText = "active";
        } else if (Status === "Blocked") {
          classText = "blocked";
        }
        return <p className={classText}>{Status}</p>;
      },
    },
    {
      title: "Starting Date",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      sorter: true,
      render: (CreatedAt) => {
        const date = new Date(CreatedAt);
        return <p>{date.toLocaleDateString('en-US')}</p>;
      },
    },
    {
      title: "",
      key: "action",
      render: (_: any, record: DataType) => {
        return (
          <div className="action-box" key={record.ID}>
            <Tooltip title="Edit Bureau">
              <Button
                className="action-btn"
                onClick={() => onEditClick(record.ID)}
              >
                <img src={images.Edit} alt="edit" />
              </Button>
            </Tooltip>
            {Boolean(record.IsActive) === true ? (
              <Tooltip title="Delete Bureau">
                <Button
                  className="action-btn"
                  onClick={() => showDeleteConfirm(record.ID)}
                >
                  <img src={images.Delete} alt="delete" />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Disabled">
                <Button className="action-btn">
                  <img src={images.Delete} alt="delete" />
                </Button>
              </Tooltip>
            )}
            {record.Status === "Blocked" ? (
              <Tooltip title="Unblock Bureau">
                <Button
                  className="action-btn"
                  onClick={() => handleStatusChange(record.ID, "Active")}
                >
                  {" "}
                  <img src={images.UnBlock} alt="block" />{" "}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Block Bureau">
                <Button
                  className="action-btn"
                  onClick={() => handleStatusChange(record.ID, "Blocked")}
                >
                  {" "}
                  <img src={images.Block} alt="unblock" />{" "}
                </Button>
              </Tooltip>
            )}
            {record.Status === "Pending" ? (
              <Tooltip title="Mail">
                <Button
                  className="action-btn"
                  onClick={() => handleReInviteBureau(record.ID)}
                >
                  {" "}
                  <img src={images.Mail} alt="mail" />{" "}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Mail Disabled">
                <Button className="action-btn">
                  {" "}
                  <img src={images.MailDisabled} alt="mail-disabled" />{" "}
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="bureaus" />
          <Content>
            <PageContentHeader
              headerTitle="List of Bureaus"
              showSearchBar={true}
              showStatus={false}
              MapSelect={false}
              searchText="Bureau"
              onSearchfunction={handleSearch}
            />
            <div className="list-table">
              <Table
                columns={columns}
                tableLayout="auto"
                dataSource={data}
                pagination={{
                  current: currentPage,
                  pageSize: PAGE_SIZE,
                  total: totalPages ? totalPages * PAGE_SIZE : 0,
                  showSizeChanger: false,
                  showQuickJumper: false,
                  onChange: handlePageChange,
                  position: ["bottomCenter"],
                }}
                scroll={{ x: "max-content" }}
                onChange={handleTableChange}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default withAuth(BureauList);
