import React, { useEffect, useState } from "react";
import images from "../../../Assets/Images";
import { Card, Button, Form, Input } from "antd";
import AuthLogoComponent from "../../../Components/CoreComponents/AuthLogoComponent";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import Loader from "../../../Components/CoreComponents/Loader";
import "../Auth.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../../../Network/interceptor";
import { apiRoutes } from "../../../Network/apiRoutes";
import { toast } from "react-toastify";

const validateMessages = {
  required: "${name} is required!",
  types: {
    email:
      "$export const Login = (title: any) => {{name} is not a valid email!",
  },
};
interface FormValues {
  Token: string | null;
  NewPassword: string;
  RePassword: string;
}

export const CreatePassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<FormValues>({
    Token: "",
    NewPassword: "",
    RePassword: "",
  });
  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const token = searchParams.get("token");
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
      Token: token,
    });
  };
  const handleReset = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await form.validateFields();
      const response = await api.post(apiRoutes.set_password, formData);
      setIsLoading(true)
      if (response.status === 200) {
        toast.success(response.data.message);
        navigate("/");
        setIsLoading(false)

      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        toast.error(error.response.data.message);
        setIsLoading(false)
      }
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="auth_module">
        <AuthLogoComponent />
        <Card className="Auth_card forgot-card" style={{ width: "469px" }}>
          <div className="forgot-icon">
            <img src={images.Key} alt="key" className="key" />
          </div>
          <h3>Create Password</h3>
          <Form
            name="nest-messages"
            form={form}
            onSubmitCapture={handleReset}
            validateMessages={validateMessages}
          >
            <Form.Item name="NewPassword" rules={[{ required: true }]}>
              <Input.Password
                onChange={handleFormChange}
                name="NewPassword"
                placeholder="Enter New Password"
                iconRender={(visible) =>
                  visible ? <EyeFilled /> : <EyeInvisibleFilled />
                }
              />
            </Form.Item>
            <Form.Item
              name="RePassword"
              dependencies={["NewPassword"]}
              rules={[
                { required: true, message: "Please confirm your password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("NewPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                name="RePassword"
                placeholder="Re-enter New password"
                iconRender={(visible) =>
                  visible ? <EyeFilled /> : <EyeInvisibleFilled />
                }
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};
