import React, { useEffect, useState } from "react";
import { Layout } from "antd";

// Components
import { Sidebar } from "../../../Components/Sidebar/Sidebar";
import { HeaderBar } from "../../../Components/Header/Header";
import PageContentHeader from "../../../Components/CoreComponents/PageContentHeader";
import BureauFormComponent from "../../../Components/CoreComponents/BureauFormComponent";
import Loader from "../../../Components/CoreComponents/Loader";

// Utils
import withAuth from "../../../Utils/wrapper";

//CSS
import "../Bureau.css";

const { Content } = Layout;

function AddBureau() {
  return (
    <>
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="bureaus" />
          <Content>
            <PageContentHeader
              headerTitle="Add Bureau"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box">
              <BureauFormComponent FormTitle="bureau/create" />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
export default withAuth(AddBureau);
