import React from "react";
import { Form, Input } from "antd";

const FormItemInputComponent = ({
  label,
  name,
  className,
  placeholder,
  inputType,
  suffix,
  onChangeFunction,
  rules,
  maxLength,
  disabled = false,
}: any) => {
  return (
    <Form.Item label={label} name={name} className={className} rules={rules}>
      <Input
        name={name}
        onChange={onChangeFunction}
        type={inputType}
        placeholder={placeholder}
        suffix={suffix}
        disabled={disabled}
        maxLength={maxLength}
      />
    </Form.Item>
  );
};

export default FormItemInputComponent;
