import React, { useEffect, useState } from 'react';
import { Layout, Button, Form, Input, Row, Col } from 'antd';
import { toast } from 'react-toastify';

// Components
import { Sidebar } from '../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../Components/Header/Header';
import PageContentHeader from '../../Components/CoreComponents/PageContentHeader';
import Loader from '../../Components/CoreComponents/Loader';

// Network
import api from '../../Network/interceptor';
import { apiRoutes } from '../../Network/apiRoutes';

// Images
import { DownloadOutlined } from '@ant-design/icons';

// Css
import '../BureauListPages/Bureau.css';
import withAuth from '../../Utils/wrapper';

const { Content } = Layout;
interface FormValues {
  BulkUploadFile: File | null | undefined | unknown;
}

function BulkEmployerUpload() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<FormValues>({
    BulkUploadFile: undefined,
  });
  const handleFileSelected = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFormData({ BulkUploadFile: files[0] });
    }
  };
  const handleDownloadSampleFile = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(apiRoutes.employer_sample_csv_download);
      if (response.status === 200) {
        setIsLoading(false);
        window.open(response.data.data.SampleFile, '_blank');
      }
    } catch (error: any | unknown) {
      if (error.response && error.response.status === 400) {
        setIsLoading(false);
        toast.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await api.post(
        apiRoutes.bulk_employer_uploader,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (response.status === 200) {
        setIsLoading(false);
        toast.success(response.data.message);
      }
    } catch (error: any | unknown) {
      if (error.response && error.response.status === 400) {
        setIsLoading(false);
        toast.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="employer/bulk/upload" />
          <Content>
            <PageContentHeader
              headerTitle="Bulk Employer Upload"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box">
              <Form
                className="common-form change_pwd_form"
                layout="vertical"
                form={form}
                name="nest-messages"
                onSubmitCapture={onSubmit}>
                <Row
                  gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}
                  className="upload-data-row">
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 10 }}
                    xl={{ span: 10 }}>
                    <Form.Item className="upload-file-item">
                      <Input
                        type="file"
                        onChange={handleFileSelected}
                        required={true}
                        name="BulkUploadFile"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 14 }}
                    xl={{ span: 14 }}>
                    <div className="upload-file-btn bulk-upload-btn">
                      <Button className="common-btn" htmlType="submit">
                        Upload
                      </Button>
                      <Button
                        className="common-btn dl-btn"
                        icon={<DownloadOutlined />}
                        onClick={handleDownloadSampleFile}>
                        Download Template
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default withAuth(BulkEmployerUpload);
