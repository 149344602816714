import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from "./Pages/AuthModule/AdminLogin";
import { ForgotPassword } from "./Pages/AuthModule/AdminForgotPassword";
import BureauList from "./Pages/BureauListPages/BureauList/BureauList";
import AddBureau from "./Pages/BureauListPages/AddBureau/AddBureau";
import EditBureau from "./Pages/BureauListPages/EditBureau/EditBureau";
import ChangePassword from "./Pages/BureauListPages/ChangePassword/ChangePassword";
import UploadMasterCSVFile from "./Pages/BureauListPages/UploadMasterCSVFile/UploadMasterCSVFile";
import { CreatePassword } from "./Pages/AuthModule/CreatePassword";
import Dashboard from "./Pages/DashboardPages/Dashboard";
import BulkEmployerUpload from "./Pages/DashboardPages/BulkEmployerUpload";
import EmployerList from "./Pages/EmployerListPages/EmployerList/EmployerList";
import AddEmployer from "./Pages/EmployerListPages/AddEmployer/AddEmployer";
import EditEmployer from "./Pages/EmployerListPages/EditEmployer/EditEmployer";
import Mapping from "./Pages/Mapping";
import { ErrorPage } from "./Pages/ErrorPage";

export const AppRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback="">
          <Routes>
            <Route path="/" element={<Login title="Login" />} />
            <Route
              path="/bureau-login"
              element={<Login title="Login" />}
            />
            <Route path="/password/forgot" element={<ForgotPassword />} />
            <Route path="/bureaus" element={<BureauList />} />
            <Route path="/bureau/create" element={<AddBureau />} />
            <Route path="/bureau/edit/:id" element={<EditBureau />} />
            <Route path="/password/change" element={<ChangePassword />} />
            <Route path="/upload/master-csv" element={<UploadMasterCSVFile />} />
            <Route path="/reset-password" element={<CreatePassword />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/employer/bulk/upload"
              element={<BulkEmployerUpload />}
            />
            <Route path="/employers" element={<EmployerList />} />
            <Route path="/employer/create" element={<AddEmployer />} />
            <Route path="/employer/edit/:id" element={<EditEmployer />} />
            <Route path="/mapping/:id" element={<Mapping />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};
