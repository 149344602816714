import React, { useEffect, useState } from 'react';
import { Layout, Button, Radio, Row, Col, Card, Space, Checkbox } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import type { RadioChangeEvent, UploadProps } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { toast } from 'react-toastify';

// Components
import { Sidebar } from '../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../Components/Header/Header';
import PageContentHeader from '../../Components/CoreComponents/PageContentHeader';
import Loader from '../../Components/CoreComponents/Loader';

// Images
import images from '../../Assets/Images/index';

// Css
import './Mapping.css';

// Network
import { apiRoutes } from '../../Network/apiRoutes';
import api from '../../Network/interceptor';
import withAuth from '../../Utils/wrapper';

const { Content } = Layout;
interface Provider {
  label: string;
  value: number;
}
interface FileType {
  label: string;
  value: string;
}
interface MappedField {
  SourceFieldId: {
    ID: string;
    FileID: string;
    FieldTitle: string;
    IsActive: string;
  };
  TargetField: any;
}
interface RadioOptions {
  label: string;
  value: any;
  style: object;
  disabled: boolean;
  checked: boolean;
  required: string;
}
function Mapping() {
  const [sourceValue, setSourceValue] = useState({
    ID: '',
    FileID: '',
    FieldTitle: '',
    IsActive: '',
  });
  const [outputValue, setOutputValue] = useState<CheckboxValueType[] | any[]>(
    [],
  );
  const [providers, setProviders] = useState<Provider[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [targetFields, setTargetFields] = useState<RadioOptions[]>([]);
  const [sourceFields, setSourceFields] = useState<RadioOptions[]>([]);
  const [mappedField, setMappedField] = useState<MappedField[]>([]);
  const [providerID, setProviderID] = useState();
  const [fileType, setFileType] = useState('');
  const [fileTypeOptions, setFileTypeOptions] = useState<FileType[]>([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const UploadUpdatedCsv = async (event: any) => {
    setIsLoading(true);
    const file_data = { SampleFile: event.target.files[0] };
    try {
      const response = await api.post(
        apiRoutes.employer + '/update-source-file',
        file_data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setIsLoading(false);
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        toast.error(error.response.data.message);
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const fetchProviders = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`${apiRoutes.provider}/?EmpID=${id}`);

      const results: Provider[] = [];
      // Store results in the results array
      response.data.data.forEach((value: any) => {
        results.push({
          label: value.Name,
          value: value.ID,
        });
      });
      setProviders(results);
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };
  const radioStyle = {
    display: 'flex',
    height: '40px',
    lineHeight: '20px',
  };
  const fetchSourceFields = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(apiRoutes.employer + `/get/source-fields`);
      const results: RadioOptions[] = [];
      response.data.data.forEach((value: any) => {
        if (value.FieldTitle !== '') {
          results.push({
            label: value.FieldTitle,
            value: value,
            style: radioStyle,
            disabled: false,
            checked: false,
            required: 'Y',
          });
        }
      });
      setSourceFields(results);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchProviders();
    fetchSourceFields();
  }, []);

  const fetchTargetFields = async (id: number | undefined) => {
    setIsLoading(true);
    try {
      const response = await api.get(
        apiRoutes.employer + `/${id}/get-target-fields/?fileType=${fileType}`,
      );
      const results: RadioOptions[] = [];
      response.data.data.TargetFieldsJSON.forEach((value: any) => {
        if (value.output_header_field !== '') {
          results.push({
            label: value.output_header_field,
            value: value,
            style: radioStyle,
            disabled: false,
            checked: false,
            required: value.required,
          });
        }
      });
      setTargetFields(results);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (providerID && fileType) {
      fetchTargetFields(providerID);
    }
  }, [fileType]);

  const onSourceChange = (e: RadioChangeEvent) => {
    setSourceValue(e.target.value);
  };
  const onOutputChange = (checkedValue: Array<CheckboxValueType>) => {
    setOutputValue([...checkedValue]);
  };
  const fetchFileTypes = async (providerID: number) => {
    setIsLoading(true);
    try {
      const response = await api.get(
        apiRoutes.employer + `/${providerID}/get-file-type/?EmpID=${id}`,
      );
      const results: FileType[] = [];
      response.data.data.FileType.forEach((value: any) => {
        results.push({
          label: value,
          value: value,
        });
      });
      setFileTypeOptions(results);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };
  const onProviderSelect = async (e: any) => {
    setProviderID(e.target.value);
    setMappedField([]);
    fetchFileTypes(e.target.value);
  };
  const onFileTypeSelect = async (e: any) => {
    setFileType(e.target.value);
    setMappedField([]);
    setTargetFields([]);
  };
  const handleDownloadCsv = async (e: any) => {
    try {
      const response = await api.post(apiRoutes.employer + `/download-csv`);
      window.open(response.data.data.FileName, '_blank');
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const handleMapping = async () => {
    if (targetFields.length !== 0) {
      if (sourceValue.ID !== '' && outputValue.length > 0) {
        const temp = [...mappedField];
        temp.push({ SourceFieldId: sourceValue, TargetField: outputValue });
        setMappedField(temp);
        sourceFields.forEach((value: any, index: number) => {
          if (value.value === sourceValue) {
            const tempSourceFields = [...sourceFields];
            const tempSourceValue: any | undefined | RadioOptions =
              tempSourceFields.at(index);
            tempSourceValue['disabled'] = true;
            tempSourceFields.splice(index, tempSourceValue);
            setSourceFields(tempSourceFields);
          }
        });

        let tempOutputValueIds = [...outputValue];
        tempOutputValueIds = tempOutputValueIds.map((temp) => temp.field);
        const tempFields = targetFields?.map((obj) => {
          if (tempOutputValueIds?.includes(obj.value.field)) {
            return { ...obj, disabled: true };
          } else {
            return obj;
          }
        });
        setTargetFields(tempFields);
        setOutputValue([]);
        setSourceValue({
          ID: '',
          FileID: '',
          FieldTitle: '',
          IsActive: '',
        });
      } else {
        toast.warning('No Mapping fields selected !');
      }
    } else {
      toast.warning('Please select Provider to get Target Fields');
    }
  };

  const onCancelMapping = async (item: any, target: any) => {
    sourceFields.forEach((value: any, index: number) => {
      if (value.value === item.SourceFieldId) {
        const tempSourceFields = [...sourceFields];
        const tempSourceValue: any | undefined | RadioOptions =
          tempSourceFields[index];
        Object.keys(tempSourceValue).forEach((key) => {
          if (item.TargetField.length === 1) {
            if (key === 'disabled') {
              tempSourceValue[key] = false;
            }
            if (key === 'checked') {
              tempSourceValue[key] = false;
            }
          }
        });

        setSourceFields(tempSourceFields);
      }
    });

    targetFields.forEach((value: any, index: number) => {
      if (value.value === target) {
        const tempTargetFields = [...targetFields];
        const tempTargetValue: any | undefined | RadioOptions =
          tempTargetFields[index];
        tempTargetValue['disabled'] = false;
        setTargetFields(tempTargetFields);
      }
    });

    const updatedMapped = mappedField.map((mappedItem) => {
      if (mappedItem === item) {
        const updatedTargetField = mappedItem.TargetField.filter(
          (field: any) => field !== target,
        );
        return { ...mappedItem, TargetField: updatedTargetField };
      }
      return mappedItem;
    });
    setMappedField(updatedMapped);

    setOutputValue([]);
    setSourceValue({
      ID: '',
      FileID: '',
      FieldTitle: '',
      IsActive: '',
    });
  };

  const handleSave = async () => {
    const requiredFields = targetFields.filter(
      (field: any) => field.value.required === 'Y',
    );

    const mappedFieldIds = mappedField.map(
      (value: MappedField) => value.TargetField,
    );

    const missingFields = requiredFields.filter((field: any) => {
      return !mappedFieldIds.some((mappedFieldId: any) => {
        return mappedFieldId.some((id: any) => id === field.value);
      });
    });

    if (missingFields.length > 0) {
      const missingFieldNames = missingFields.map(
        (field: any) => field.value.output_header_field,
      );
      const errorMessage = `Missing required fields to map:<br>${missingFieldNames.join(
        '<br>',
      )}`;

      toast.warn(<div dangerouslySetInnerHTML={{ __html: errorMessage }} />, {
        toastId: 'missing-fields-error',
      });
      return false;
    }
    if (mappedField.length !== 0) {
      try {
        const preMappingData: any = [];
        mappedField.forEach((value: any) => {
          if (value.TargetField.length !== 0) {
            preMappingData.push({
              SourceFieldId: value.SourceFieldId.ID,
              TargetField: value.TargetField,
            });
          }
        });
        const mapping_data = {
          Mapping: preMappingData,
          ProviderID: providerID,
          FileType: fileType,
        };
        const response = await api.post(
          apiRoutes.employer + `/${id}/map`,
          mapping_data,
        );
        toast.success(response.data.message);
        navigate('/employers');
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
    } else {
      toast.warning(
        'No Fields selected for Mapping. Please select some fields',
      );
    }
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(apiRoutes.employer + `/get/source-fields`);
      const results: RadioOptions[] = [];
      response.data.data.forEach((value: any) => {
        if (value.FieldTitle !== '') {
          results.push({
            label: value.FieldTitle,
            value: value,
            style: radioStyle,
            disabled: false,
            checked: false,
            required: 'Y',
          });
        }
      });
      setSourceFields(results);
      setTargetFields([]);
      setMappedField([]);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error('Something went Wrong !!');
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="bureaus" />
          <Content>
            <PageContentHeader
              headerTitle="Map Required Field"
              showSearchBar={false}
              showStatus={false}
              MapSelect={true}
              providerData={providers}
              onProviderSelect={onProviderSelect}
              fileTypeOptions={fileTypeOptions}
              onFileTypeSelect={onFileTypeSelect}
            />

            <div className="list-table mapping-data">
              <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 7 }}>
                  <div className="Mapping-card-wrapper">
                    <div className="mapping-card-header">
                      <h3>Source File</h3>
                      <div className="map-card-action">
                        <img
                          src={images.RefreshIcon}
                          alt="refresh"
                          onClick={handleRefresh}
                        />
                        <img
                          src={images.DownloadIcon}
                          alt="download"
                          onClick={handleDownloadCsv}
                        />
                        <input
                          type="file"
                          id="updateCsv"
                          hidden={true}
                          onChange={UploadUpdatedCsv}
                        />

                        <img
                          src={images.UploadIcon}
                          alt="upload"
                          onClick={() => {
                            document.getElementById('updateCsv')?.click();
                          }}
                        />
                      </div>
                    </div>
                    <Card
                      className="Map-card"
                      style={{ width: '100%', height: '469px' }}>
                      <div className="radio-btns">
                        <Radio.Group
                          onChange={onSourceChange}
                          options={sourceFields}
                          name="sourceradio"></Radio.Group>
                      </div>
                    </Card>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 7 }}>
                  <div className="Mapping-card-wrapper">
                    <div className="mapping-card-header">
                      <h3>Target Output File</h3>
                    </div>
                    <Card
                      className="Map-card"
                      style={{ width: '100%', height: '469px' }}>
                      <div className="radio-btns">
                        <Checkbox.Group
                          name="targetradio"
                          onChange={onOutputChange}
                          options={targetFields}
                          value={outputValue}>
                          <Space size={[8, 20]} direction="vertical"></Space>
                        </Checkbox.Group>
                      </div>
                    </Card>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 2 }}>
                  <div className="map-btn">
                    <Button onClick={handleMapping} className="common-btn">
                      Map
                    </Button>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 8 }}>
                  <div className="Mapping-card-wrapper">
                    <div className="mapping-card-header">
                      <h3>Result File</h3>
                    </div>
                    <Card
                      className="Map-card"
                      style={{ width: '100%', height: '469px' }}>
                      <div className="output-wrapper-box">
                        {mappedField.map((item, index) =>
                          item.TargetField.map(
                            (target: any, targetIndex: any) => (
                              <div
                                className="radio-list-item"
                                key={`mapped_${index}_${targetIndex}`}>
                                <div className="radio-btns map-result-btns">
                                  <Space
                                    size={[12, 12]}
                                    direction="horizontal"
                                    wrap={true}>
                                    <Radio
                                      defaultChecked
                                      value={item.SourceFieldId}>
                                      {item.SourceFieldId.FieldTitle}
                                    </Radio>
                                    <div className="target-field-entry">
                                      <Radio defaultChecked value={target}>
                                        {target.output_header_field}
                                      </Radio>
                                      <Link to="">
                                        <img
                                          key={`${targetIndex}`}
                                          src={images.CloseIcon}
                                          alt="close"
                                          onClick={() => {
                                            onCancelMapping(item, target);
                                          }}
                                        />
                                      </Link>
                                    </div>
                                  </Space>
                                </div>
                              </div>
                            ),
                          ),
                        )}
                      </div>
                    </Card>
                  </div>
                </Col>
              </Row>
              <div className="test-save-btn">
                <Button className="common-btn" onClick={handleSave}>
                  test & save
                </Button>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default withAuth(Mapping);
