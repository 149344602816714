// Third party
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// Reducer
import authReducer from "./Reducer/Authentication/Authentication";


const reducer = combineReducers({
  authReducer,
});

export default configureStore({
  reducer,
});
