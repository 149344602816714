import React from "react";
import { Layout, Popover, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Images
import images from "../../Assets/Images";

const { Header } = Layout;

export const HeaderBar: any = ({ activePopupLink }: any) => {
  const navigate = useNavigate();
  let userUid = useSelector((state: any) => state.authReducer.ID);
  if (userUid === "") {
    userUid = localStorage.getItem("name");
  }
  const handleClick = () => {
    // Perform logout action (e.g. clear local storage or make API call)
    if (localStorage.getItem("user_type") === "ADMIN") {
      localStorage.clear();
      navigate("/");
    } else {
      localStorage.clear();
      navigate("/bureau-login");
    }
  };

  const content = (
    <div className="user-popover">
      <p>
        <Link
          to="/password/change"
          className={activePopupLink === "password/change" ? "active-link" : ""}
        >
          Change Password
        </Link>
      </p>
      <p>
        {localStorage.getItem("user_type") === "ADMIN" ? (
          <Link to="/" onClick={handleClick}>
            Logout
          </Link>
        ) : (
          <Link to="/bureau-login" onClick={handleClick}>
            Logout
          </Link>
        )}
      </p>
    </div>
  );
  return (
    <Header className="header">
      {localStorage.getItem("user_type") === "ADMIN" ? (
        <Link to="/bureaus">
          <img src={images.AuthLogo} alt="logo" />
        </Link>
      ) : (
        <Link to="/dashboard">
          <img src={images.AuthLogo} alt="logo" />
        </Link>
      )}

      <div className="Header-top-content">
        <Popover content={content} trigger="click">
          <Button>{userUid}</Button>
        </Popover>
        <p>{localStorage.getItem("user_type")}</p>
      </div>
    </Header>
  );
};
