import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Select } from 'antd';
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import FormItemInputComponent from './FormItemInputComponent';
import FormNumberItemInputComponent from './FormNumberInputComponent';

// Images
import images from '../../Assets/Images';

// Network
import { apiRoutes } from '../../Network/apiRoutes';
import api from '../../Network/interceptor';

// Static values
import { states } from '../../Utils/UsStates';
import { defaultCountries } from '../../Utils/DefaultCountries';
import { EmployerStatus, UserStatus } from '../../Utils/BureauStatus';

interface Plan {
  label: string;
  value: number;
}
interface Provider {
  label: string;
  value: number;
}
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
  },
};

const EmployerEditFormComponent = ({ FormTitle }: any) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [providers, setProviders] = useState<Provider[]>([]);

  const fetchProviders = async () => {
    try {
      const response = await api.get(apiRoutes.provider);

      const results: Provider[] = [];
      // Store results in the results array
      response.data.data.forEach((value: any) => {
        results.push({
          label: value.Name,
          value: value.ID,
        });
      });
      setProviders(results);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  const fetchEmployer = async () => {
    try {
      const response = await api.get(apiRoutes.employer + `/${id}`);
      setFormData({
        ...formData,
        EmployerPlansID: response.data.data.EmployerPlansID,
        ProviderID: response.data.data.EmployerPlan.ProvidersID,
      });
      form.setFieldValue(
        'CurrentPlanID',
        response.data.data.EmployerPlan.PlanID,
      );
      form.setFieldValue(
        'ProviderID',
        response.data.data.EmployerPlan.ProvidersID,
      );
      form.setFields([
        { name: 'CurrentPlanID', touched: true },
        { name: 'ProviderID', touched: true },
      ]);
      form.setFieldsValue(response.data.data);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };
  
  useEffect(() => {
    fetchProviders();
    fetchEmployer();
  }, []);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === 'ProviderID') {
      const ProviderID = parseInt(value, 10);
      setFormData({
        ...formData,
        ProviderID: ProviderID,
      });
    } else if (name === 'IsActive') {
      const IsActive = Boolean(value);
      setFormData({
        ...formData,
        IsActive: IsActive,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validatedFields = await form.validateFields();
    if (validatedFields) {
      try {
        const response = await api.put(apiRoutes.employer + `/${id}`, formData);
        if (response.status === 200) {
          toast.success(response.data.message);
          navigate('/employers');
        }
      } catch (error: any) {
        if (error.response?.status === 400) {
          toast.error(error.response.data.message);
        }
      }
    }
  };
  return (
    <Form
      className="common-form employer-form"
      layout="vertical"
      form={form}
      onSubmitCapture={onSubmit}
      validateMessages={validateMessages}>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}>
          <FormItemInputComponent
            label="Legal Name"
            name="LegalName"
            className="legal-name"
            placeholder="Enter Legal Name"
            inputType="text"
            suffix={<img src={images.LegalName} alt="name" />}
            rules={[{ required: true }]}
            onChangeFunction={handleChange}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}>
          <FormItemInputComponent
            label="Legal EIN"
            name="LegalEIN"
            className=""
            placeholder="Enter Legal EIN"
            inputType="text"
            suffix={null}
            rules={[{ required: true }]}
            onChangeFunction={handleChange}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}>
          <FormItemInputComponent
            label="Email Address"
            name="Email"
            className=""
            disabled={true}
            placeholder="Enter Email Address"
            inputType="text"
            suffix={<MailOutlined />}
            rules={[{ type: 'email', required: true }]}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}>
          <FormItemInputComponent
            label="Main Contact Name"
            name="ContactName"
            className=""
            placeholder="Enter Main Contact Name"
            inputType="text"
            suffix={null}
            rules={[{ required: true }]}
            onChangeFunction={handleChange}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}>
          <FormItemInputComponent
            label="Phone number"
            name="PhoneNumber"
            className=""
            placeholder="Enter Primary Phone number"
            inputType="text"
            suffix={<PhoneOutlined />}
            rules={[
              { required: true },
              {
                pattern: '^(\\+?\\d{1,4}[-\\.\\s]?\\(?\\d{1,4}\\)?[-\\.\\s]?\\d{1,4}[-\\.\\s]?\\d{1,4}[-\\.\\s]?\\d{1,4}|\\(\\d{3}\\)[-]?\\d{3}[-]?\\d{4})$',
                message: 'Please enter a valid mobile number',
              },
            ]}
            onChangeFunction={handleChange}
          />
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 16 }}>
          <FormItemInputComponent
            label="Address"
            name="Address"
            className=""
            placeholder="Enter Address"
            inputType="text"
            suffix={<EnvironmentOutlined />}
            rules={[{ required: true }]}
            onChangeFunction={handleChange}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}>
          <FormItemInputComponent
            label="City"
            name="City"
            onChangeFunction={handleChange}
            className=""
            placeholder="Enter City"
            inputType="text"
            suffix={<EnvironmentOutlined />}
            rules={[{ required: true }]}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}>
          <Form.Item label="State" name="State" rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder="Choose State"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              style={{ width: '100%' }}
              onSelect={(event) =>
                handleChange({ target: { name: 'State', value: event } })
              }
              options={states}
            />
          </Form.Item>
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}>
          <Form.Item label="Country" name="Country">
            <Select
              defaultValue="US"
              disabled={true}
              placeholder="Choose Country"
              optionFilterProp="children"
              style={{ width: '100%' }}
              options={[defaultCountries]}
            />
          </Form.Item>
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}>
          <FormNumberItemInputComponent
            label="Zip"
            name="Zip"
            onChangeFunction={handleChange}
            className="remove-control"
            placeholder="Enter ZipCode"
            rules={[{ required: true }]}
            max={5}
          />
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}>
          <Form.Item label="Current Provider" name="ProviderID">
            <Select
              showSearch={true}
              onSelect={(event) =>
                handleChange({ target: { name: 'ProviderID', value: event } })
              }
              placeholder="Select Provider"
              style={{ width: '100%' }}
              options={providers}
            />
          </Form.Item>
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}>
          <FormItemInputComponent
            label="Current Plan ID"
            optionFilterProp="children"
            name="CurrentPlanID"
            onChangeFunction={handleChange}
            className=""
            placeholder="Enter Current Plan ID"
            inputType="text"
            suffix={<TagOutlined />}
            rules={[{ required: true }]}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}>
          <FormItemInputComponent
            label="Site ID"
            name="SiteID"
            onChangeFunction={handleChange}
            className="legal-name"
            placeholder="Enter Site ID"
            inputType="text"
            suffix={<img src={images.LegalName} alt="name" />}
          />
        </Col>
        {FormTitle === 'employer/edit' ? (
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}>
            <Form.Item label="Status" name="Status">
              <Select
                showSearch={false}
                disabled={true}
                placeholder="Choose Status"
                style={{ width: '100%' }}
                onSelect={(event) =>
                  handleChange({ target: { name: 'Status', value: event } })
                }
                options={EmployerStatus}
              />
            </Form.Item>
          </Col>
        ) : null}
        {FormTitle === 'employer/edit' ? (
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}>
            <Form.Item label="Employer Status" name="IsActive">
              <Select
                showSearch
                placeholder="Choose Employer Status"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                style={{ width: '100%' }}
                onSelect={(event) =>
                  handleChange({
                    target: { name: 'IsActive', value: Boolean(event) },
                  })
                }
                options={UserStatus}
              />
            </Form.Item>
          </Col>
        ) : null}
      </Row>

      <Form.Item>
        <div className="bottom-btn-section">
          <Button
            className="btn-link"
            onClick={() => {
              navigate('/employers');
            }}>
            Cancel
          </Button>
          <Button className="common-btn" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default EmployerEditFormComponent;
