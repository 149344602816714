import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Sidebar } from "../../../Components/Sidebar/Sidebar";
import { HeaderBar } from "../../../Components/Header/Header";
import PageContentHeader from "../../../Components/CoreComponents/PageContentHeader";
import EmployerEditFormComponent from "../../../Components/CoreComponents/EmployerEditFormComponent";
import Loader from "../../../Components/CoreComponents/Loader";
import "../../BureauListPages/Bureau.css";
import withAuth from "../../../Utils/wrapper";

const { Content } = Layout;

function EditEmployer() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    });
  });
  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="bureaus" />
          <Content>
            <PageContentHeader
              headerTitle="Edit Employer"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box">
              <EmployerEditFormComponent FormTitle="employer/edit" />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
export default withAuth(EditEmployer)