import React, { useState } from "react";
import { Card, Button, Form } from "antd";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";

// Networking
import api from "../../Network/interceptor";
import { apiRoutes } from "../../Network/apiRoutes";

// Components
import FormItemInputComponent from "./FormItemInputComponent";

// Images
import images from "../../Assets/Images";

interface FormValues {
  Email: string;
}
const ForgotCardComponent = ({ validateMessages, backToLoginLink }: any) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormValues>({
    Email: "",
  });
  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData.Email !== "") {
      try {
        const response = await api.post(apiRoutes.forget_password, formData);
        if (response.status === 200) {
          toast.success(response.data.message);
          navigate("/");
        }
      } catch (error: any) {
        if (error.response?.status === 400) {
          toast.error(error.response.data.message);
        }
      }
    }
  };

  return (
    <Card className="Auth_card forgot-card" style={{ width: "469px" }}>
      <div className="forgot-icon">
        <img src={images.Key} alt="key" className="key" />
      </div>
      <h3>Forgot Password?</h3>
      <p>No worries! We’ll help you change</p>
      <Form
        name="nest-messages"
        onSubmitCapture={handleSubmit}
        validateMessages={validateMessages}
      >
        <FormItemInputComponent
          label={null}
          name="Email"
          onChangeFunction={handleFormChange}
          className=""
          placeholder="Enter registered e-mail"
          inputType="text"
          suffix={<MailOutlined />}
          rules={[{ type: "email", required: true }]}
        />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            send link
          </Button>
        </Form.Item>
        <div className="backtologin">
          <Link to={backToLoginLink}>
            <img src={images.Arrow} alt="arrow" className="arrow" /> Back to
            login
          </Link>
        </div>
      </Form>
    </Card>
  );
};

export default ForgotCardComponent;
