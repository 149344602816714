import React, { useEffect, useState } from "react";
import AuthLogoComponent from "../../../Components/CoreComponents/AuthLogoComponent";
import ForgotCardComponent from "../../../Components/CoreComponents/ForgotCardComponent";
import Loader from "../../../Components/CoreComponents/Loader";
import "../Auth.css";

const validateMessages = {
  required: "${name} is required!",
  types: {
    email: "${name} is not valid!",
  },
};

export const ForgotPassword = () => {
  return (
    <>
      <div className="auth_module">
        <AuthLogoComponent />
        <ForgotCardComponent
          validateMessages={validateMessages}
          backToLoginLink="/"
        />
      </div>
    </>
  );
};
