import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Sidebar } from "../../../Components/Sidebar/Sidebar";
import { HeaderBar } from "../../../Components/Header/Header";
import PageContentHeader from "../../../Components/CoreComponents/PageContentHeader";
import BureauEditFormComponent from "../../../Components/CoreComponents/BureauEditFormComponent";
import Loader from "../../../Components/CoreComponents/Loader";
import "../Bureau.css";
import withAuth from "../../../Utils/wrapper";

const { Content } = Layout;

function EditBureau() {
  return (
    <>
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="bureaus" />
          <Content>
            <PageContentHeader
              headerTitle="Edit Bureau"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box">
              <BureauEditFormComponent FormTitle="edit-bureau" />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
export default withAuth(EditBureau);
