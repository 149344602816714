import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  auth: any;
  ID: string;
  token: any;
}

const initialState: AuthState = {
  auth: {},
  ID: '',
  token: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<any>) => {
      state.auth = action.payload;
    },
    clearAuthData: (state) => {
      state.auth = {};
    },
    setUserUid: (state, action: PayloadAction<any>) => {
      state.ID = action.payload;
    },
    setToken: (state, action: PayloadAction<any>) => {
      state.token = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuthData, clearAuthData, setUserUid, setToken } =
  authSlice.actions;

export default authSlice.reducer;
