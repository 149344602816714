import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});
api.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = config.headers || {}; // initialize headers if it's undefined
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError | any) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      location.reload()
    }

    return Promise.reject(error);
  }
);

export default api;
