import React, { useEffect, useState } from 'react';
import { Layout, Button, Table, Modal, Tooltip } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// Components
import { Sidebar } from '../../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../../Components/Header/Header';
import PageContentHeader from '../../../Components/CoreComponents/PageContentHeader';
import Loader from '../../../Components/CoreComponents/Loader';

// Images
import images from '../../../Assets/Images';

// Css
import './EmployerList.css';
import type { ColumnsType } from 'antd/es/table';

// Network
import { apiRoutes } from '../../../Network/apiRoutes';
import api from '../../../Network/interceptor';
import withAuth from '../../../Utils/wrapper';

const { Content } = Layout;
const { confirm } = Modal;

function EmployerList() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'ascend' | 'descend' | undefined>(
    undefined,
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isStatusChange, setIsStatusChange] = useState(false);
  const [userStatus, setUserStatus] = useState('');
  const [employerStatus, setEmployerStatus] = useState('');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // Data Fetch functions
  const PAGE_SIZE = 10;

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let status: any = "";
      if (userStatus) {
        status = userStatus;
      } else if (searchParams.get("status")) {
        status = searchParams.get("status");
        setUserStatus(status)
      }

      let tempSortOrder = "";
      if (sortField && sortOrder) {
        tempSortOrder = `${sortOrder === 'ascend' ? 'ASC' : 'DESC'}`;
      }
      const response = await api.get(
        apiRoutes.employer +
          `?PageNumber=${
            currentPage - 1
          }&PageSize=${PAGE_SIZE}&SortBy=${sortField}&OrderBy=${tempSortOrder}&Search=${searchQuery}&UserStatus=${employerStatus}&Status=${status}`,
      );
      setData(response.data.data);
      setTotalPages(response.data.Pages);
      setIsLoading(false);
    } catch (error: any) {
      if (error.response.status === 401) {
        if (localStorage.getItem('user_type') === 'ADMIN') navigate('/');
      } else {
        navigate('/bureau-login');
      }
    }
  };

  // Handle Page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  // Search function
  const handleSearch = (query: string) => {
    setSearchQuery(query);
    handlePageChange(1);
  };
  // Handle Sorting
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortField(sorter.field || '');
    setSortOrder(sorter.order);
  };
  const onStatusChangefunction = (event: any) => {
    if (event.name === 'user') {
      setEmployerStatus(event.value);
    } else {
      setUserStatus(event.value);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    sortField,
    sortOrder,
    searchQuery,
    isDeleting,
    isStatusChange,
    userStatus,
    employerStatus,
  ]);
  

  const onEditClick = (id: number) => {
    navigate(`/employer/edit/${id}`);
  };
  // Delete Employer
  const handleDelete = async (id: number) => {
    try {
      const response = await api.delete(apiRoutes.employer + `/${id}`);
      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  // Status Change Employer
  const handleStatusChange = async (id: number, status: string) => {
    try {
      const data = {
        Status: status,
      };
      const response = await api.post(
        apiRoutes.employer + `/${id}/status-change`,
        data,
      );
      if (response.status === 200) {
        if (isStatusChange === false) {
          setIsStatusChange(true);
        } else {
          setIsStatusChange(false);
        }
        toast.success(response.data.message);
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  const onMapClick = (id: number) => {
    navigate(`/mapping/${id}`);
  };

  const showDeleteConfirm = (id: number) => {
    const modal = confirm({
      title: 'Are you sure you want to deactivate this Employer?',
      wrapClassName: 'delete-modal-wrapper',
      className: 'delete-modal',
      okText: 'Yes',
      cancelText: 'No',
      width: 592,
      centered: true,
      closable: true,
      onOk() {
        if (id !== null) {
          handleDelete(id);
          if (isDeleting === false) {
            setIsDeleting(true);
          } else if (isDeleting === true) {
            setIsDeleting(false);
          }
        }
      },
      onCancel() {
        modal.destroy();
      },
    });
  };

  interface DataType {
    IsActive: boolean;
    ID: number;
    LegalName: string;
    LegalEIN: number;
    Status: string;
    CreatedAt: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'LegalName',
      key: 'LegalName',
      sorter: true,
    },
    {
      title: 'EIN',
      dataIndex: 'LegalEIN',
      key: 'LegalEIN',
      sorter: true,
    },
    {
      title: 'Status',
      key: 'Status',
      dataIndex: 'Status',
      sorter: true,
      render: (Status) => {
        let classText = '';
        if (Status === 'Mapped') {
          classText = 'active';
        } else if (Status === 'Un-Mapped') {
          classText = 'blocked';
        }
        return <p className={classText}>{Status}</p>;
      },
    },
    {
      title: 'Starting Date',
      dataIndex: 'CreatedAt',
      key: 'CreatedAt',
      sorter: true,
      render: (CreatedAt) => {
        const date = new Date(CreatedAt);
        return <p key={CreatedAt}>{date.toLocaleDateString('en-US')}</p>;
      },
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => {
        return (
          <div className="action-box" key={record.ID}>
            <Tooltip title="Edit Employer">
              <Button
                className="action-btn"
                onClick={() => {
                  onEditClick(record.ID);
                }}>
                <img src={images.Edit} alt="edit" />
              </Button>
            </Tooltip>
            {Boolean(record.IsActive) === true ? (
              <Tooltip title="Deactivate Employer">
                <Button
                  className="action-btn"
                  onClick={() => showDeleteConfirm(record.ID)}>
                  <img src={images.Delete} alt="delete" />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Deactivated Employer">
                <Button className="action-btn">
                  <img src={images.Delete} alt="delete" />
                </Button>
              </Tooltip>
            )}
            {record.Status === 'Un-Mapped' ? (
              <Tooltip title="Disabled">
                <Button className="action-btn">
                  <img src={images.UnBlock} alt="block" />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Unmap Employer">
                <Button
                  className="action-btn"
                  onClick={() => handleStatusChange(record.ID, 'Un-Mapped')}>
                  {' '}
                  <img src={images.Block} alt="unblock" />
                </Button>
              </Tooltip>
            )}
            {record.Status === 'Un-Mapped' || record.Status === 'Partially-Mapped' ? (
              <Tooltip title="Mapping">
                <Button
                  className="action-btn"
                  onClick={() => {
                    onMapClick(record.ID);
                  }}>
                  <img src={images.MappedIcon} alt="Mapped" />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Mapping">
                <Button className="action-btn">
                  <img src={images.UnMappedIcon} alt="unmapped" />
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="employers" />
          <Content>
            <PageContentHeader
              headerTitle="List of Employer"
              showSearchBar={true}
              showStatus={true}
              MapSelect={false}
              searchText="Employer"
              onSearchfunction={handleSearch}
              onStatusChangefunction={onStatusChangefunction}
              statusvalue={userStatus}
            />
            <div className="list-table">
              <Table
                columns={columns}
                tableLayout="auto"
                dataSource={data}
                pagination={{
                  current: currentPage,
                  pageSize: PAGE_SIZE,
                  total: totalPages ? totalPages * PAGE_SIZE : 0,
                  onChange: handlePageChange,
                  position: ['bottomCenter'],
                }}
                onChange={handleTableChange}
                scroll={{ x: 'max-content' }}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default withAuth(EmployerList);
