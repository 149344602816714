import { Layout } from "antd";
import React, { useEffect, useState } from "react";

// Components
import { Sidebar } from "../../../Components/Sidebar/Sidebar";
import { HeaderBar } from "../../../Components/Header/Header";
import PageContentHeader from "../../../Components/CoreComponents/PageContentHeader";
import ChangePasswordForm from "../../../Components/CoreComponents/ChangePasswordForm";
import Loader from "../../../Components/CoreComponents/Loader";

// Css
import "../Bureau.css";

// Utils
import withAuth from "../../../Utils/wrapper";

const { Content } = Layout;

const validateMessages = {
  required: "${label} is required!",
  passwordsDontMatch: "Passwords do not match!",
};

function ChangePassword() {
  return (
    <>
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="password/change" />
        <Layout>
          <Sidebar currentVal="" />
          <Content>
            <PageContentHeader
              headerTitle="Change Password"
              showSearchBar={false}
              showStatus={false}
              MapSelect={false}
            />
            <div className="Main-content-box change-pwd-contbox">
              <ChangePasswordForm validateMessages={validateMessages} />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
export default withAuth(ChangePassword);
