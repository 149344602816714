import React, { useEffect, useState } from "react";

// Components
import AuthLogoComponent from "../../../Components/CoreComponents/AuthLogoComponent";
import LoginCardComponent from "../../../Components/CoreComponents/LoginCardComponent";
import Loader from "../../../Components/CoreComponents/Loader";

// Css
import "../Auth.css";

const validateMessages = {
  required: "${name} is required!",
  types: {
    email: "${name} is not a valid email!",
  },
};

export const Login = (title: any) => {
  return (
    <>
      <div className="auth_module">
        <AuthLogoComponent />
        <LoginCardComponent
          cardTitle={title.title}
          validateMessages={validateMessages}
          forgotLink="/password/forgot"
        />
      </div>
    </>
  );
};
