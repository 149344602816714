import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Select, Upload, UploadFile } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import type { UploadProps } from "antd";
import {
  UploadOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  MailOutlined,
} from "@ant-design/icons";

// Networking
import api from "../../Network/interceptor";
import { apiRoutes } from "../../Network/apiRoutes";

// Components
import FormNumberItemInputComponent from "./FormNumberInputComponent";
import FormItemInputComponent from "./FormItemInputComponent";
import Loader from "../CoreComponents/Loader";

// Static Values
import { states } from "../../Utils/UsStates";
import { bureauStatus } from "../../Utils/BureauStatus";
import { defaultCountries } from "../../Utils/DefaultCountries";

// Images
import images from "../../Assets/Images";

const validateMessages = {
  required: "${label} is required!",
};

const BureauEditFormComponent = ({ FormTitle }: any) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [changeData, setChangeData] = useState({});

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setChangeData({
      ...changeData,
      [name]: value,
    });
  };

  useEffect(() => {
    getBureauData();
  }, []);
  const props: UploadProps = {
    onChange(info) {
      setChangeData({
        ...changeData,
        SampleFile: info.file.originFileObj,
      });
    },
  };
  const getBureauData = async () => {
    try {
      const response = await api.get(apiRoutes.bureau + `/${id}`);
      setIsLoading(true);
      if (response.status === 200) {
        const bureauDataResponse = {
          Email: response.data.data.User.Email,
          LegalName: response.data.data.User.LegalName,
          PrimaryContact: response.data.data.PrimaryContact,
          PrimaryContactPhoneNumber:
            response.data.data.PrimaryContactPhoneNumber,
          PrimaryContactMobile: response.data.data.PrimaryContactMobile,
          Address: response.data.data.Address,
          City: response.data.data.City,
          State: response.data.data.State,
          Country: response.data.data.Country,
          Zip: response.data.data.Zip,
          Status: response.data.data.Status,
        };
        form.setFieldsValue(bureauDataResponse);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.response?.status === 400) {
        return {};
      }
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validatedFields = await form.validateFields();
    if (validatedFields) {
      try {
        await form.validateFields();

        const response = await api.put(
          apiRoutes.bureau + `/${id}`,
          changeData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          toast.success(response.data.message);
          navigate("/bureaus");
        }
      } catch (error: any) {
        if (error.response?.status === 400) {
          toast.error(error.response.data.message);
        }
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Form
        className="common-form"
        layout="vertical"
        form={form}
        onSubmitCapture={onSubmit}
        validateMessages={validateMessages}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <FormItemInputComponent
              label="Legal Name"
              name="LegalName"
              onChangeFunction={handleChange}
              className="legal-name"
              placeholder="Enter Legal Name"
              inputType="text"
              suffix={<img src={images.LegalName} alt="name" />}
              rules={[ { required: true }]}
            />
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <FormItemInputComponent
              label="Primary Contact"
              name="PrimaryContact"
              onChangeFunction={handleChange}
              className=""
              placeholder="Enter Primary Contact"
              inputType="text"
              suffix={null}
              rules={[{ required: true }]}
            />
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <FormItemInputComponent
              label="Primary Contact Phone Number"
              name="PrimaryContactPhoneNumber"
              onChangeFunction={handleChange}
              className=""
              placeholder="Enter Primary Contact Phone Number"
              inputType="text"
              suffix={<PhoneOutlined />}
              rules={[
                { required: true },
                {
                  pattern: "^(\\+?\\d{1,4}[-\\.\\s]?\\(?\\d{1,4}\\)?[-\\.\\s]?\\d{1,4}[-\\.\\s]?\\d{1,4}[-\\.\\s]?\\d{1,4}|\\(\\d{3}\\)[-]?\\d{3}[-]?\\d{4})$",
                  message: "Please enter a valid Phone number",
                },
              ]}
            />
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}
          >
            <FormItemInputComponent
              label="Primary Contact Mobile"
              name="PrimaryContactMobile"
              onChangeFunction={handleChange}
              className=""
              placeholder="Enter Primary Contact Mobile"
              inputType="text"
              suffix={<PhoneOutlined />}
              rules={[
                { required: true },
                {
                pattern: "^(\\+?\\d{1,4}[-\\.\\s]?\\(?\\d{1,4}\\)?[-\\.\\s]?\\d{1,4}[-\\.\\s]?\\d{1,4}[-\\.\\s]?\\d{1,4}|\\(\\d{3}\\)[-]?\\d{3}[-]?\\d{4})$",
                message: "Please enter a valid mobile number",
                },
              ]}
            />
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}
          >
            <FormItemInputComponent
              label="Primary Contact Email"
              name="Email"
              disabled={true}
              className=""
              inputType="text"
              suffix={<MailOutlined />}
              rules={[{ required: true }]}
            />
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}
          >
            <FormItemInputComponent
              label="Address"
              name="Address"
              onChangeFunction={handleChange}
              className=""
              placeholder="Enter Address"
              inputType="text"
              suffix={<EnvironmentOutlined />}
              rules={[{ required: true }]}
            />
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <FormItemInputComponent
              label="City"
              name="City"
              onChangeFunction={handleChange}
              className=""
              placeholder="Enter City"
              inputType="text"
              suffix={<EnvironmentOutlined />}
              rules={[{ required: true }]}
            />
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <Form.Item label="State" name="State" rules={[{ required: true }]}>
              <Select
                showSearch
                placeholder="Choose State"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                style={{ width: "100%" }}
                onSelect={(event) =>
                  handleChange({ target: { name: "State", value: event } })
                }
                options={states}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <Form.Item
              label="Country"
              name="Country"
              rules={[{ required: true }]}
            >
              <Select
                defaultValue="US"
                disabled={true}
                placeholder="Choose Country"
                optionFilterProp="children"
                style={{ width: "100%" }}
                onSelect={(event) =>
                  handleChange({ target: { name: "Country", value: event } })
                }
                options={defaultCountries}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <FormNumberItemInputComponent
              label="Zip"
              name="Zip"
              onChangeFunction={handleChange}
              className="remove-control"
              placeholder="Enter Zipcode"
              rules={[
                {
                  max: 5,
                  message: "Zip code is only 5 digit long",
                },
                { required: true }
              ]}
              max={5}
            />
          </Col>

          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <Form.Item label="Bureau Status" name="Status">
              <Select
                showSearch
                placeholder="Choose Bureau Status"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                style={{ width: "100%" }}
                onSelect={(event) =>
                  handleChange({ target: { name: "Status", value: event } })
                }
                options={bureauStatus}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
          >
            <Form.Item label="Sample File" name="SampleFile">
              <Upload
                accept=".csv"
                {...props}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Upload File here</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <div className="bottom-btn-section">
            <Button
              onClick={() => {
                navigate("/bureaus");
              }}
              className="btn-link"
            >
              Cancel
            </Button>
            {FormTitle === "bureau/create" ? (
              <Button htmlType="submit" className="common-btn">
                Send invite
              </Button>
            ) : (
              <Button htmlType="submit" className="common-btn">
                Save
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default BureauEditFormComponent;
