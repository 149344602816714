import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import { Menu, Layout } from "antd";
import images from "../../Assets/Images";

const { Sider } = Layout;

export const Sidebar = ({ currentVal }: any) => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(currentVal);
  const UserType = localStorage.getItem("user_type");
  let select_options = [];
  if (UserType === "ADMIN") {
    select_options = [
      {
        key: "bureaus",
        icon: <img src={images.AppAddIcon} alt="app-add" />,
      },
      {
        key: "upload/master-csv",
        icon: <img src={images.UploadCSVIcon} alt="upload-csv" />,
      },
    ];
  } else {
    select_options = [
      {
        key: "employers",
        icon: <img src={images.AppAddIcon} alt="app-add" />,
      },
      {
        key: "employer/bulk/upload",
        icon: <img src={images.UploadCSVIcon} alt="upload-csv" />,
      },
    ];
  }
  const items: MenuProps["items"] = select_options;

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    navigate(`/${e.key}`);
  };

  return (
    <Sider
      className="sidebar"
      breakpoint="lg"
      collapsedWidth="0"
      width={100}
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div className="sidebar-logo">
        <img src={images.AuthLogo} alt="logo" />
      </div>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="vertical"
        items={items}
      />
      ;
    </Sider>
  );
};
