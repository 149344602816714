import React from "react";
import { Form, Input } from "antd";
const FormNumberItemInputComponent = ({
  label,
  name,
  className,
  placeholder,
  suffix,
  max,
  onChangeFunction,
  rules,
}: any) => {
  return (
    <Form.Item label={label} name={name} className={className} rules={rules}>
      <Input
        name={name}
        onChange={onChangeFunction}
        type="number"
        placeholder={placeholder}
        suffix={suffix}
        maxLength={max}
        pattern="\d*"
      />
    </Form.Item>
  );
};

export default FormNumberItemInputComponent;
