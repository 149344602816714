import React, { useState } from "react";
import { Button, Form, Input, Row, Col } from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Networking
import { apiRoutes } from "../../Network/apiRoutes";
import api from "../../Network/interceptor";

interface FormValues {
  OldPassword: string;
  NewPassword: string;
  RePassword: string;
}

const ChangePasswordForm = ({ validateMessages }: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormValues>({
    OldPassword: "",
    NewPassword: "",
    RePassword: "",
  });

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData.OldPassword !== "" && formData.NewPassword !== "") {
      await form.validateFields();
      try {
        const response = await api.post(apiRoutes.change_password, formData);
        if (response.status === 200) {
          localStorage.clear();
          navigate("/");
          toast.success(response.data.message);
        }
      } catch (error: any) {
        toast.error(error.response.data.message);
      }
    }
  };
  return (
    <Form
      className="common-form change_pwd_form"
      layout="vertical"
      form={form}
      name="nest-messages"
      onSubmitCapture={onSubmit}
      validateMessages={validateMessages}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Form.Item label="Old Password" name="OldPassword" rules={[{ required: true }]}>
            <Input.Password
              name="OldPassword"
              onChange={handleFormChange}
              placeholder="Enter Old Password"
              iconRender={(visible) =>
                visible ? <EyeFilled /> : <EyeInvisibleFilled />
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Form.Item label="New Password" name="NewPassword" rules={[{ required: true }]}>
            <Input.Password
              name="NewPassword"
              onChange={handleFormChange}
              placeholder="Enter New Password"
              iconRender={(visible) =>
                visible ? <EyeFilled /> : <EyeInvisibleFilled />
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <Form.Item
            name="Re-enter New Password"
            label= "Confirm Password"
            dependencies={["NewPassword"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("NewPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              name="RePassword"
              placeholder="Re-enter New Password"
              iconRender={(visible) =>
                visible ? <EyeFilled /> : <EyeInvisibleFilled />
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 30 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <div className="change-pwd-btn">
            <Button className="common-btn" htmlType="submit">
              Change password
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangePasswordForm;
