export const bureauStatus = [
  { label: "Pending", value: "Pending" },
  { label: "Active", value: "Active" },
  { label: "Blocked", value: "Blocked" },
];

export const EmployerStatus = [
  { label: "Mapped", value: "Mapped" },
  { label: "Un-Mapped", value: "Un-Mapped" },
  { label: "Re-Mapped", value: "Re-Mapped" },
  { label: "Partially-Mapped", value: "Partially-Mapped" },
];

export const UserStatus = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const FilterUserStatus = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "InActive" },
];
