import React from 'react';
import {AppRoutes} from './AppRoutes';
import './App.css';
import './Assets/Css/Common.css'
import ToasterComponent from './Components/UI/toaster';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './Store/store';

function App() {
  
  return (
    <div className="App">
      <Provider store={store}>
      <AppRoutes/>
      <ToasterComponent/>
      </Provider>
    </div>
  );
}

export default App;
