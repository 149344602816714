import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Card, Button, Form, Input } from "antd";
import { EyeFilled, EyeInvisibleFilled, MailOutlined } from "@ant-design/icons";

// Networking
import api from "../../Network/interceptor";
import { apiRoutes } from "../../Network/apiRoutes";

// Components
import FormItemInputComponent from "./FormItemInputComponent";

// Store
import { setUserUid } from "../../Store/Reducer/Authentication/Authentication";

interface FormValues {
  Email: string;
  Password: string;
}
const LoginCardComponent = ({
  cardTitle,
  validateMessages,
  forgotLink,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<FormValues>({
    Email: "",
    Password: "",
  });
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const user_type = localStorage.getItem('user_type');
      if (user_type === "ADMIN") {
        navigate("/bureaus");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData.Email !== "" && formData.Password !== "") {
      try {
        const response = await api.post(apiRoutes.login, formData);
        if (response.status === 200) {
          const jwt_token = "jwt " + response.data.data.Token;
          dispatch(setUserUid(response.data.data.LegalName));
          localStorage.setItem("token", jwt_token);
          localStorage.setItem("name", response.data.data.LegalName);
          localStorage.setItem("user_type", response.data.data.Type);
          if (response.data.data.Type === "ADMIN") {
            navigate("/bureaus");
          } else {
            navigate("/dashboard");
          }
          toast.success(response.data.message);
        }
      } catch (error: any) {
        if (error.response?.status === 400) {
          toast.error(error.response.data.message);
        }
      }
    }
  };
  return (
    <Card className="Auth_card" style={{ width: "469px" }}>
      <h3>{cardTitle}</h3>
      <Form
        name="nest-messages"
        onSubmitCapture={handleSubmit}
        validateMessages={validateMessages}
      >
        <FormItemInputComponent
          label={null}
          name="Email"
          className=""
          onChangeFunction={handleFormChange}
          placeholder="Enter e-mail"
          inputType="text"
          suffix={<MailOutlined />}
          rules={[{ type: "email", required: true }]}
        />
        <Form.Item name="Password" rules={[{ required: true }]}>
          <Input.Password
            name="Password"
            onChange={handleFormChange}
            placeholder="Enter password"
            iconRender={(visible) =>
              visible ? <EyeFilled /> : <EyeInvisibleFilled />
            }
          />
        </Form.Item>
        <div className="forgotpassword">
          <Link to={forgotLink}>Forgot Password?</Link>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default LoginCardComponent;
