import React, { useState } from 'react';
import { Select, Input, Tag } from 'antd';
import { Link } from 'react-router-dom';

// Images
import images from '../../Assets/Images';

// Static Values
import { EmployerStatus, FilterUserStatus } from '../../Utils/BureauStatus';

const { Search } = Input;

const PageContentHeader = ({
  headerTitle,
  showSearchBar,
  showStatus,
  MapSelect,
  onSearchfunction,
  onStatusChangefunction,
  searchText,
  providerData,
  onProviderSelect,
  statusvalue,
  fileTypeOptions,
  onFileTypeSelect,
}: any) => {
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);

  const searchBoxOpen = () => {
    setSearchBoxVisible(!searchBoxVisible);
  };

  return (
    <div
      className={`page-content-header ${
        showSearchBar ? 'searchbox-header' : ''
      } ${MapSelect ? 'searchbox-header map-page-header' : ''} `}>
      <h3>{headerTitle}</h3>
      {showSearchBar || MapSelect ? (
        <div
          className={`pagecontent-header-rightbox ${
            showStatus ? 'employer-header-content' : ''
          }`}>
          {MapSelect && (
            <>
            <Select
              showSearch={false}
              className="vendor-select"
              placeholder="Choose Provider"
              style={{ width: 280 }}
              optionFilterProp="children"
              onSelect={(event) =>
                onProviderSelect({ target: { name: 'Provider', value: event } })
              }
              popupClassName="status-popup"
              options={providerData}
            />
             <Select
              showSearch={false}
              className="vendor-select"
              placeholder="Choose File Type"
              style={{ width: 280 }}
              optionFilterProp="children"
              onSelect={(event) =>
                onFileTypeSelect({ target: { name: 'FileType', value: event } })
              }
              popupClassName="status-popup"
              options={fileTypeOptions}
            />
            </>
          )}
          {showStatus && (
            <>
            <Select
              id="employer-status-filter"
              showSearch={false}
              placeholder="Employer Status"
              optionFilterProp="children"
              onChange={(event) => {
                onStatusChangefunction({ name: 'user', value: event });
              }}
              popupClassName="status-popup"
              options={FilterUserStatus}
            />
            <div>
              <Select
                showSearch={false}
                className="status-select"
                placeholder="Status"
                optionFilterProp="children"
                onChange={(event) => {
                  onStatusChangefunction({ name: 'employer', value: event });
                }}
                popupClassName="status-popup"
                options={EmployerStatus}
                value={statusvalue ? statusvalue : null}
              />
            </div>
            </>
          ) }
          {showSearchBar ? (
            <div className="search-box">
              <Search
                className={`searchinput ${
                  searchBoxVisible ? 'searchboxopen' : 'searchboxclose'
                }`}
                placeholder={`Search ${searchText}`}
                allowClear
                enterButton={false}
                onSearch={onSearchfunction}
              />
              <img src={images.Search} alt="search" onClick={searchBoxOpen} />
            </div>
          ) : null}

          {showStatus && showSearchBar ? (
            <Link to="/employer/create" className="common-btn">
              Add employer
            </Link>
          ) : null}
          {showSearchBar && !showStatus ? (
            <Link to="/bureau/create" className="common-btn">
              Add bureau
            </Link>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default PageContentHeader;
